<template>
  <div>
    <div class="login_container"></div>
    <div v-if="isLogin" id="login-form">
      <div class="login-title"><img src="@/assets/img/top-menu_icon_temperature.png" style="height: 100%; vertical-align: bottom" />TI-5000平台</div>
      <div class="login_box">
        <div id="login-type">
          <div
            class="title-text"
            :style="{ 'border-bottom': loginType === 'password' ? 'solid 3px #1E1EF2' : '' }"
            @click="changeLoginType('password')"
          >
            密码登录
          </div>
          <div style="width: 1px; height: 18px; background: #c0c4cc"></div>
          <div
            class="title-text"
            :style="{ 'border-bottom': loginType === 'message' ? 'solid 3px #1E1EF2' : '' }"
            @click="changeLoginType('message')"
          >
            短信登录
          </div>
        </div>
        <div id="login-detail">
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            label-position="top"
            hide-required-asterisk
            @keyup.enter.native="dataFormSubmit()"
            :status-icon="false"
          >
            <el-form-item key="account" prop="account" label="账号" v-if="loginType === 'password'">
              <el-input v-model="dataForm.account" placeholder="请输入用户名/邮箱"></el-input>
            </el-form-item>
            <el-form-item key="password" prop="password" label="密码" v-if="loginType === 'password'">
              <el-input v-model="dataForm.password" :type="passwordType" placeholder="请输入密码">
                <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
                  <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
                  <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
                </div>
                ></el-input
              >
            </el-form-item>
            <el-form-item key="phone" prop="phone" label="手机号" v-if="loginType === 'message'">
              <el-input v-model="dataForm.phone" placeholder="请输入手机号">
                <el-select v-model="dataForm.crownCode" slot="prepend" placeholder="请选择">
                  <el-option value="+86"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item key="validCode" prop="validCode" label="验证码" v-if="loginType === 'message'">
              <div style="position: relative">
                <el-input v-model="dataForm.validCode" placeholder="请输入验证码"></el-input>
                <span style="position: absolute; right: 12px">
                  <span v-if="btnLoading" style="color: #5053dd">{{ countDown }}</span>
                  <el-button v-else @click="getValidCode" :loading="btnLoading" type="text">获取验证码</el-button>
                </span>
              </div>
            </el-form-item>
            <div class="forget-register">
              <div @click="toRegister()">注册账号</div>
              <div @click="toForget()" v-show="loginType === 'password'">忘记密码</div>
            </div>
            <el-form-item style="margin-bottom: 0; text-align: center">
              <el-button class="login-btn-submit" type="primary" @click="dataFormSubmit()">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- <div class="logo_img"><img src="../assets/img/logo.png" /></div> -->
    </div>
    <div v-else id="register-form" :style="{ height: isForget && isNext ? '455px' : '580px' }">
      <i class="el-icon-close" @click="toLogin"></i>
      <div id="register-title">{{ isForget ? (isNext ? '重置密码' : '忘记密码') : '欢迎注册 ' }}</div>
      <div id="to-login">
        <div v-if="!isForget">已有账号？<el-button type="text" @click="toLogin">登录</el-button></div>
        <div v-else-if="!isNext">
          无法验证？<el-tooltip effect="dark" placement="top">
            <div slot="content">国内热线：400-999-2131<br />国外热线：0086-400-999-2131</div>
            <el-button type="text">技术支持</el-button>
          </el-tooltip>
        </div>
      </div>

      <div v-if="!isNext">
        <div id="register-type">
          <div
            class="title-text"
            :style="{ 'border-bottom': registerType === 'email' ? 'solid 3px #1E1EF2' : '' }"
            @click="changeRegisterType('email')"
          >
            {{ isForget ? '邮箱验证' : '通过邮箱' }}
          </div>
          <div style="width: 1px; height: 18px; background: #c0c4cc"></div>
          <div
            class="title-text"
            :style="{ 'border-bottom': registerType === 'phone' ? 'solid 3px #1E1EF2' : '' }"
            @click="changeRegisterType('phone')"
          >
            {{ isForget ? '短信验证' : '通过短信' }}
          </div>
        </div>
      </div>

      <div id="register-detail">
        <el-form
          v-if="!isNext"
          :model="dataForm"
          :rules="dataRule"
          ref="dataForm"
          label-position="top"
          hide-required-asterisk
          @keyup.enter.native="nextStep"
          :status-icon="false"
        >
          <el-form-item key="email" prop="email" label="邮箱" v-if="registerType === 'email'">
            <el-input v-model="dataForm.email" placeholder="请输入邮箱地址"></el-input>
          </el-form-item>
          <el-form-item key="phone" prop="phone" label="手机号" v-if="registerType === 'phone'">
            <el-input v-model="dataForm.phone" placeholder="请输入手机号">
              <el-select v-model="dataForm.crownCode" slot="prepend" placeholder="请选择">
                <el-option value="+86"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item key="validCode" prop="validCode" label="验证码">
            <el-input v-model="dataForm.validCode" placeholder="请输入验证码"></el-input>
            <span style="position: absolute; right: 12px">
              <span v-if="btnLoading" style="color: #5053dd">{{ countDown }}</span>
              <el-button v-else @click="getValidCode" :loading="btnLoading" type="text">获取验证码</el-button>
            </span>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button class="login-btn-submit" type="primary" @click="nextStep()">下一步</el-button>
          </el-form-item>
          <el-form-item v-if="!isForget" prop="check" style="text-align: center" class="tos-form-item">
            <div class="user-rule">
              <el-checkbox v-model="dataForm.check"></el-checkbox>
              <span>
                同意并接受燧石技术<el-button type="text" @click="toTOS(1)">《用户服务协议》</el-button>及<el-button type="text" @click="toTOS(2)"
                  >《隐私政策》</el-button
                ></span
              >
            </div>
          </el-form-item>
        </el-form>

        <el-form
          v-else
          :model="dataForm"
          :rules="dataRule"
          ref="dataForm"
          label-position="top"
          hide-required-asterisk
          @keyup.enter.native="dataFormSubmit"
          :status-icon="false"
        >
          <el-form-item key="username" prop="username" label="用户名" v-if="!isForget">
            <el-input v-model="dataForm.username" placeholder="输入以设置用户名"></el-input>
            <span style="position: absolute; right: -28px">
              <el-tooltip effect="dark" placement="top" :tabindex="-1">
                <div slot="content">1.英文或数字，区分大小写<br />2.长度为5~50个字符</div>
                <el-image :src="require(`@/assets/img/icon_info_def.png`)"> </el-image>
              </el-tooltip>
            </span>
          </el-form-item>

          <el-form-item key="registerPassword" label="密码" prop="registerPassword">
            <el-input v-model="dataForm.registerPassword" :type="passwordType" placeholder="输入以设置密码">
              <div slot="suffix" @click="showPassword = !showPassword" class="password-suffix">
                <i v-if="showPassword" class="el-input__icon el-icon-view"></i>
                <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
              </div>
              ></el-input
            >
            <span style="position: absolute; right: -28px">
              <el-tooltip effect="dark" placement="top" :tabindex="-1">
                <div slot="content">
                  1.长度为8~14个字符<br />2.字母、数字以及特殊符号至少包含2种<br />3.不允许有空格、中文<br />
                  4.区分大小写
                </div>
                <el-image :src="require(`@/assets/img/icon_info_def.png`)"> </el-image>
              </el-tooltip>
            </span>
          </el-form-item>

          <el-form-item key="comfirmPassword" label="确认密码" prop="comfirmPassword">
            <el-input v-model="dataForm.comfirmPassword" :type="comfirmPasswordType" placeholder="输入以确认密码">
              <div slot="suffix" @click="showComfirmPassword = !showComfirmPassword" class="password-suffix">
                <i v-if="showComfirmPassword" class="el-input__icon el-icon-view"></i>
                <el-image v-else :src="require(`@/assets/img/icon_preview_close.png`)"> </el-image>
              </div>
              ></el-input
            >
          </el-form-item>

          <el-form-item style="text-align: center">
            <el-button class="login-btn-submit" type="primary" @click="finish">{{ isForget ? '保存' : '完成注册，进入平台' }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      dataForm: {
        account: '', // 登录用户名或邮箱
        username: '', // 注册用户名
        registerPassword: '', // 注册忘记密码
        password: '', // 登录密码
        comfirmPassword: '', // 注册确认密码
        crownCode: '+86',
        phone: '',
        validCode: '',
        email: '',
        check: false
      },
      dataRule: {
        account: [{ required: true, message: '帐号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        username: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('用户名不能为空'))
              } else if (!/^[a-zA-Z0-9]{5,50}$/g.test(value)) {
                callback(new Error('用户名仅支持英文或数字，长度为5~50个字符'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        registerPassword: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('密码不能为空'))
              } else if (!/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,14}$/g.test(value)) {
                callback(new Error('请设置长度为8~14个字符，字母、数字以及特殊符号至少包含2种，无空格和中文的密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        comfirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('确认密码不能为空'))
              } else if (this.dataForm.registerPassword !== value) {
                callback(new Error('两次密码输入不一致'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('手机号不能为空'))
              } else if (!/^(?:(?:\+|00)86)?1\d{10}$/g.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        validCode: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        email: [
          {
            validator: (rule, value, callback) => {
              if (!/\S/.test(value)) {
                callback(new Error('邮箱不能为空'))
              } else if (
                !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
                  value
                )
              ) {
                callback(new Error('请输入正确的邮箱'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        check: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请勾选“同意并接受燧石技术用户协议”'))
              } else {
                callback()
              }
            },
            trigger: 'change'
          }
        ]
      },
      isLogin: true,
      loginType: 'password',
      registerType: 'email',
      isNext: false,
      btnLoading: false,
      countDown: 120,
      countDownIntervalId: undefined,
      showPassword: false,
      showComfirmPassword: false,
      isForget: false
    }
  },
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    comfirmPasswordType() {
      return this.showComfirmPassword ? 'text' : 'password'
    }
  },
  created() {
    // this.getCaptcha()
  },
  methods: {
    // 注册或重置
    registerOrReset() {
      const { username, registerPassword, email, crownCode, phone, validCode } = this.dataForm
      this.$http({
        url: this.$http.adornUrl(`/api/v1/auth/${this.isForget ? 'forgetPassword' : 'registerUser'}`),
        method: 'POST',
        data: this.$http.adornData({
          username: username || undefined,
          password: registerPassword || undefined,
          newPassword: registerPassword || undefined,
          email: email || undefined,
          crownCode: phone ? crownCode : undefined,
          phoneNum: phone || undefined,
          validateCode: validCode,
          contact: phone || email
        })
      }).then((res) => {
        if (res.code === 0) {
          if (this.isForget) {
            this.$message({
              message: this.isForget ? '操作成功' : '注册成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.isLogin = true
                this.isForget = false
                this.resetForm()
              }
            })
          } else {
            // 直接登录
            this.dataForm.account = username
            this.dataForm.password = registerPassword
            this.dataFormSubmit(true)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 完成注册
    finish() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.isForget) {
            this.registerOrReset()
          } else {
            // 校重
            this.$http({
              url: this.$http.adornUrl('/api/v1/auth/checkUser'),
              method: 'get',
              params: this.$http.adornParams({
                userName: this.dataForm.username
              })
            }).then((res) => {
              if (res.code === 0) {
                this.registerOrReset()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    // 获取验证码
    getValidCode() {
      this.$refs.dataForm.validateField(this.isLogin ? 'phone' : this.registerType === 'email' ? 'email' : 'phone', (valid) => {
        if (!valid) {
          this.btnLoading = true
          // 校重
          this.$http({
            url: this.$http.adornUrl('/api/v1/auth/checkUser'),
            method: 'get',
            params: this.$http.adornParams({
              email: this.dataForm.email || undefined,
              phoneNum: this.dataForm.phone || undefined
            })
          })
            .then((res) => {
              if (this.isLogin) {
                // 短信登录
                if (res.code === 0) {
                  this.$message.error('该用户不存在')
                  this.btnLoading = false
                } else if (res.code === 3006) {
                  this.getCode()
                } else {
                  this.$message.error(res.msg)
                  this.btnLoading = false
                }
              } else if (this.isForget) {
                // 忘记密码
                if (res.code === 0) {
                  this.$message.error('该用户不存在')
                  this.btnLoading = false
                } else if ((res.code === 3006 && this.registerType === 'phone') || (res.code === 3005 && this.registerType === 'email')) {
                  this.getCode()
                } else {
                  this.$message.error(res.msg)
                  this.btnLoading = false
                }
              } else {
                // 注册
                if (res.code === 0) {
                  this.getCode()
                } else {
                  this.$message.error(res.msg)
                  this.btnLoading = false
                }
              }
            })
            .catch(() => {
              this.btnLoading = false
            })
        }
      })
    },
    // 获取倒计时
    getCountDown() {
      this.countDown--
      if (this.countDown < 1) {
        this.btnLoading = false
        clearInterval(this.countDownIntervalId)
        this.countDown = 120
      }
    },
    // 服务条款
    toTOS(type) {
      const { href } = this.$router.resolve({ name: 'TermsOfService', query: { type } })
      window.open(href, '_blank')
    },
    // 切回登录
    toLogin() {
      this.showPassword = false
      this.isLogin = true
      this.isForget = false
      this.resetForm()
    },
    // 切换注册方式
    changeRegisterType(type) {
      if (this.registerType === type) return
      this.registerType = type
      this.resetForm()
    },
    // 切换登录方式
    changeLoginType(type) {
      if (this.loginType === type) return
      this.loginType = type
      this.resetForm()
    },
    // 忘记密码
    toForget() {
      this.isForget = true
      this.isLogin = false
      this.resetForm()
    },
    // 去注册
    toRegister() {
      this.isLogin = false
      this.resetForm()
    },
    // 重置表单
    resetForm() {
      this.isNext = false
      this.dataForm = {
        account: '',
        username: '',
        registerPassword: '',
        password: '',
        comfirmPassword: '',
        crownCode: '+86',
        phone: '',
        validCode: '',
        email: '',
        check: false
      }

      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 下一步
    nextStep() {
      this.showPassword = false
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/api/v1/auth/validateCode'),
            method: 'post',
            params: this.$http.adornParams({
              email: this.dataForm.email || undefined,
              phoneNum: this.dataForm.phone || undefined,
              validateCode: this.dataForm.validCode
            })
          }).then((res) => {
            if (res.code === 0) {
              this.isNext = true
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    dataFormSubmit(pwdFlag) {
      // 注册表单验证
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/api/v1/auth/login'),
            method: 'POST',
            data: this.$http.adornData({
              username: pwdFlag || this.loginType === 'password' ? this.dataForm.account : this.dataForm.phone,
              password: pwdFlag || this.loginType === 'password' ? this.dataForm.password : this.dataForm.validCode
            })
          }).then((res) => {
            if (res.code === 0) {
              // 校验密码强度
              sessionStorage.needTip =
                this.loginType === 'password' ? !/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,14}$/g.test(this.dataForm.password) : false
              this.$cookie.set('token', res.data.token)
              this.$router.replace('/')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 获取验证码
    getCode() {
      // 判断时间
      const startCountTime = JSON.parse(sessionStorage._startCountTime || '[]')
      for (let i = 0; i < startCountTime.length; i++) {
        if (startCountTime[i].account === this.dataForm.email || startCountTime[i].account === this.dataForm.phone) {
          if ((new Date().getTime() - (startCountTime[i].sendTime || 0)) / 1000 < 120) {
            this.$message.error('您的操作太频繁了，请2分钟后重试')
            this.btnLoading = false
            return
          }
        }
      }

      this.$http({
        url: this.$http.adornUrl(`/api/v1/auth/${this.isLogin ? 'getPhoneCode' : this.registerType === 'email' ? 'getCode' : 'getPhoneCode'}`),
        method: 'POST',
        data: this.$http.adornData({
          email: this.dataForm.email || undefined,
          phone: this.dataForm.phone || undefined
        })
      })
        .then((res) => {
          if (res.code === 0) {
            // 倒计时
            this.countDown = 120
            this.countDownIntervalId = setInterval(this.getCountDown, 1000)
            // 记录时间
            startCountTime.push({ account: this.dataForm.email || this.dataForm.phone, sendTime: new Date().getTime() })
            sessionStorage._startCountTime = JSON.stringify(startCountTime)
            this.$message({
              message: this.dataForm.email ? '邮件已发送' : '短信已发送',
              type: 'success'
            })
          } else {
            this.$message.error(res.msg)
            this.btnLoading = false
          }
        })
        .catch(() => {
          this.btnLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.login_container {
  background: url('../assets/img/login.png') no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  z-index: -10;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

#login-form {
  height: 100%;
  width: 538px;
  position: absolute;
  right: 12.5%;
  text-align: center;
  .login-title {
    height: 48px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #dee4f7;
    line-height: 48px;
    margin-top: 224px;
  }

  .login_box {
    box-sizing: border-box;
    margin-top: 32px;
    border-radius: 20px;
    background: #ffffff;

    #login-type {
      width: 100%;
      border-bottom: 1px solid #cccfd6;
      height: 64px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .title-text {
        box-sizing: border-box;
        height: 64px;
        line-height: 64px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #3a3b3e;
        cursor: pointer;
      }
    }
    #login-detail {
      box-sizing: border-box;
      padding: 32px 60px 40px 60px;
      text-align: left;
    }
    ::v-deep .el-form--label-top .el-form-item__label {
      padding: 0;
    }
    .forget-register {
      display: flex;
      display: -webkit-flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      div {
        cursor: pointer;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5053dd;
        line-height: 20px;
      }
    }
    .login-captcha {
      overflow: hidden;
      > img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
  .logo_img {
    margin-top: 161px;
    img {
      width: 117px;
    }
  }
}

.login-btn-submit {
  width: 240px;
  background: #5053dd;
  border-radius: 4px;
  margin-top: 32px;
}
.login-btn-submit:active {
  background: #1d2843;
}

#register-form {
  width: 586px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(168, 171, 179, 0.3);
  border-radius: 20px;
  position: absolute;
  top: 24%;
  right: 12.5%;
  text-align: center;
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 12px;
    cursor: pointer;
  }
  #register-title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #3a3b3e;
    margin-top: 40px;
  }
  #to-login {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4d4e52;
    text-align: right;
    padding-right: 24px;
  }
  #register-type {
    width: 100%;
    border-bottom: 1px solid #cccfd6;
    height: 64px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .title-text {
      box-sizing: border-box;
      height: 64px;
      line-height: 64px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #3a3b3e;
      cursor: pointer;
    }
  }
  #register-detail {
    box-sizing: border-box;
    padding: 32px 60px 40px 60px;
    text-align: left;
  }
  ::v-deep .el-form--label-top .el-form-item__label {
    padding: 0;
  }
}

::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-select .el-input {
  width: 80px;
  .el-form-item--feedback .el-input__validateIcon {
    display: none;
  }
}

.tos-form-item {
  .user-rule {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4d4e52;
    span {
      margin-left: 4px;
    }
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  ::v-deep .el-form-item__error {
    margin-left: 11%;
    line-height: 0;
    padding-top: 0;
  }
}

.password-suffix {
  width: 25px;
  height: 100%;
  cursor: pointer;
}

::v-deep .el-image__inner {
  vertical-align: middle;
}
</style>
